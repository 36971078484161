import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthorizationManager } from '../../../core/authorization-manager';
import { LanguageProvider } from '../../../core/language-provider';
import FieldPreview from '../../../components/Entity/FieldPreview';
import ModalDetailsActionsHook from '../../../components/Hooks/ModalDetailsActions';


export default function ModalOpen({ entity, props, data = null, closeModalDetails }) {
    
    const navigate = useNavigate()
    const excluded = ['deleted']
    const [statusModalDetails, setStatusModalDetails] = useState(false)
    
    useEffect(() => {
        if(data === null)
            setStatusModalDetails(false)
        else
            setStatusModalDetails(true)
    }, [data]);

    /* panels */
    function edit() {
        if(props.reference_entity) {
            navigate('/entity/' + props.reference_entity + '/edit?id=' + data.id)
        }
        else {
            navigate('/entity/' + entity + '/edit?id=' + data.id)
        }
    }


    if(props !== null && data !== null) {
        return (
            <>
                <Modal show={statusModalDetails} onHide={closeModalDetails} size="xl" scrollable={true} id={'modal-details-'+entity} backdrop="static" >
                    <Modal.Header closeButton>
                        <Modal.Title>{LanguageProvider.get('defaults.details')} - {LanguageProvider.get('entity.'+entity)}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table striped bordered responsive className='small'>
                            <tbody>
                                {Object.keys(data).map((key, i) => {
                                    if(!excluded.includes(key)) {
                                        return (
                                            <tr key={i} data-name={'modal-details-field-'+key}>
                                                <th>
                                                    <span>{LanguageProvider.getLabel(entity, key)}</span>
                                                </th>
                                                <td>
                                                    <FieldPreview item={data} field={key} props={props} entity={entity} adv={true} />
                                                </td>
                                            </tr>
                                        )
                                    }
                                })}
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <ModalDetailsActionsHook entity={entity} data={data} />
                        {/*
                            {
                                {
                                'true' : <Button variant="outline-secondary" size="sm" onClick={() => edit()}>{LanguageProvider.get('defaults.edit')} <span className='ms-2 float-end'><FontAwesomeIcon icon="edit" /></span></Button>,
                                'false' : <></>,
                                'group' : 
                                    <>
                                        {props.authorization && data[props.authorization.groupField] && (
                                            <>
                                                {props.columns[props.authorization.groupField].input_type === 'number' ? (
                                                    <>
                                                        {data[props.authorization.groupField] == JSON.parse(localStorage.getItem('userInfo')).idUserGroup && ( 
                                                            <Button variant="outline-secondary" size="sm" onClick={() => edit()}>{LanguageProvider.get('defaults.edit')} <span className='ms-2 float-end'><FontAwesomeIcon icon="edit" /></span></Button>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {data[props.authorization.groupField].id == JSON.parse(localStorage.getItem('userInfo')).idUserGroup && ( 
                                                            <Button variant="outline-secondary" size="sm" onClick={() => edit()}>{LanguageProvider.get('defaults.edit')} <span className='ms-2 float-end'><FontAwesomeIcon icon="edit" /></span></Button>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>,
                                'user' : 
                                    <>
                                        {props.authorization && data[props.authorization.userField] && (
                                            <>
                                                {props.columns[props.authorization.userField].input_type === 'number' ? (
                                                    <>
                                                        {data[props.authorization.userField] == JSON.parse(localStorage.getItem('userInfo')).id && ( 
                                                            <Button variant="outline-secondary" size="sm" onClick={() => edit()}>{LanguageProvider.get('defaults.edit')} <span className='ms-2 float-end'><FontAwesomeIcon icon="edit" /></span></Button>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {data[props.authorization.userField].id == JSON.parse(localStorage.getItem('userInfo')).id && ( 
                                                            <Button variant="outline-secondary" size="sm" onClick={() => edit()}>{LanguageProvider.get('defaults.edit')} <span className='ms-2 float-end'><FontAwesomeIcon icon="edit" /></span></Button>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </>
                                }[AuthorizationManager.checkEntityActionAuth(entity, 'edit')]
                            }
                        */}
                        <Button variant="secondary" size="sm" onClick={closeModalDetails}>
                            {LanguageProvider.get('defaults.close')}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
};