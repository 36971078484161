import React, { useState } from 'react';
import { Container, Row, Col, Form, Button, Modal, Table } from 'react-bootstrap';
import ReactDiffViewer from 'react-diff-viewer-continued';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBalanceScale } from '@fortawesome/free-solid-svg-icons';

export default function LogCompare({ data }) {

    const [selectedId1, setSelectedId1] = useState('');
    const [selectedId2, setSelectedId2] = useState('');
    const [showModal, setShowModal] = useState(false);

    // Retrieve the selected items from the data array
    const item1 = data.find(item => String(item.id) === selectedId1);
    const item2 = data.find(item => String(item.id) === selectedId2);

    // Parse the JSON in "notes" and pretty-print it
    let leftNotes = '';
    let rightNotes = '';
    try {
        if (item1) {
            leftNotes = JSON.stringify(JSON.parse(item1.notes), null, 2);
        }
    } catch (error) {
        leftNotes = 'Error parsing JSON';
    }
    try {
        if (item2) {
            rightNotes = JSON.stringify(JSON.parse(item2.notes), null, 2);
        }
    } catch (error) {
        rightNotes = 'Error parsing JSON';
    }

    // Show modal only if both items are selected and they are not the same
    const handleCompare = () => {
        if (selectedId1 && selectedId2 && selectedId1 !== selectedId2) {
            setShowModal(true);
            console.warn(item1, item2)
        }
    };



    if (data) {

        return (
            <>
                <Container className="my-4">
                    <Row className="mb-3">
                        <Col md={6}>
                            <Form.Group controlId="selectItem1">
                                <Form.Label className='small text-muted'>Seleziona elemento 1</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedId1}
                                    onChange={(e) => setSelectedId1(e.target.value)}
                                >
                                    <option value="">-- Select an Item --</option>
                                    {data.map(item => (
                                        <option key={item.id} value={item.id}>
                                            {`${item.idUser && item.idUser.username} | ${item.date}`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md={6}>
                            <Form.Group controlId="selectItem2">
                                <Form.Label className='small text-muted'>Seleziona elemento 2</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedId2}
                                    onChange={(e) => setSelectedId2(e.target.value)}
                                >
                                    <option value="">-- Select an Item --</option>
                                    {data.map(item => (
                                        <option key={item.id} value={item.id}>
                                            {`${item.idUser && item.idUser.username} | ${item.date}`}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <Button
                                variant="primary"
                                onClick={handleCompare}
                                disabled={!selectedId1 || !selectedId2 || selectedId1 === selectedId2}
                            >
                                <FontAwesomeIcon icon={faBalanceScale} className="me-2" />
                                Compara
                            </Button>
                        </Col>
                    </Row>

                    {/* Modal for showing the diff view */}
                    <Modal
                        show={showModal}
                        onHide={() => setShowModal(false)}
                        size="xl"
                        centered
                        scrollable
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Comparazione modifiche</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Table className='mb-2 text-center small' border striped>
                                <tbody>
                                    <tr>
                                        <td>{item1.idUser ? item1.idUser.username : ''} - {item1.date}</td>
                                        <td>{item2.idUser ? item2.idUser.username : ''} - {item2.date}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            <ReactDiffViewer
                                oldValue={leftNotes}
                                newValue={rightNotes}
                                splitView={true}
                                useDarkTheme={false}
                            />
                        </Modal.Body>
                    </Modal>
                </Container>
            </>
        );
    }
};